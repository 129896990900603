import React from 'react'
import {
    apiLicensesSellMemberTaxesLookup,
    apiLicensesMemberTaxDelete,
    apiLicensesMemberTaxNew,
} from '../actions'

import {
    apiLicensesSellMemberTaxesAgeCategoriesLookup,
} from '../../global/actions'

import {isUserAuth} from '../../auth/components'

import DeleteButton from '../../buttons/DeleteButton'
import ModalDialog from '../../modal/components/ModalDialog'

export default class LicensesSellMemberTaxesComponent extends React.Component{

    constructor(props){
        super(props)
        
        this.currentAgeCategoryToDelete = ""

        this.state = ({
            memberTaxes: [],
            memberTaxesAgeCategories: [],
            deleteModalShow: false,
            newMemberTaxValid: true,
            newMemberTaxError: "",
            newMemberTaxAgeCat: "",
            newMemberTaxPrice: 0,
            isUserAuth:false,
        })

        this.loadMemberTaxes        = this.loadMemberTaxes.bind(this)
        this.loadAgeCategories      = this.loadAgeCategories.bind(this)
        this.memberTaxDeleteClicked = this.memberTaxDeleteClicked.bind(this)
        this.memberTaxDeleteOk      = this.memberTaxDeleteOk.bind(this)
        this.memberTaxDeleteCancell = this.memberTaxDeleteCancell.bind(this)
        this.newMemberTaxSubmit     = this.newMemberTaxSubmit.bind(this)
        this.loadUserAuth           = this.loadUserAuth.bind(this)
    }

    componentDidMount(){
        this.loadUserAuth()
        this.loadMemberTaxes()
    }

    loadUserAuth(){
        const userAuthDidLoad = (response, status) => {
            if(status === 200){
                if(response.is_auth && response.is_auth === "yes"){
                    this.setState({
                        isUserAuth: true,
                    })
                    this.loadAgeCategories()
                }
            }else{
                this.setState({
                    isUserAuth: false,
                })
            }      
        }
        isUserAuth(userAuthDidLoad) 
    }

    loadMemberTaxes(){
        const memberTaxesDidLoaded = (response, status) => {
            if(status===200){
                this.setState({
                    memberTaxes:response
                })
            }
        }
        apiLicensesSellMemberTaxesLookup(memberTaxesDidLoaded)
    }

    loadAgeCategories(){
        const ageCategoriesDidLoaded = (response) => {
            if(response != null){                
                this.setState({
                    memberTaxesAgeCategories:response.age_categories_dict ? response.age_categories_dict : [],
                    newMemberTaxAgeCat:response.age_categories_dict ? response.age_categories_dict[0][0] : [],
                })
            }
        }
        
        if(this.state.isUserAuth)
        {
            apiLicensesSellMemberTaxesAgeCategoriesLookup(ageCategoriesDidLoaded)
        }
    }

    memberTaxDeleteClicked(age_category){
        this.currentAgeCategoryToDelete = age_category
        this.setState({
            deleteModalShow:true
        })
    }

    memberTaxDeleteOk(){
        const memberTaxDidDelete = (_response) => {
            this.loadMemberTaxes()
        }

        apiLicensesMemberTaxDelete(memberTaxDidDelete, this.currentAgeCategoryToDelete)

        this.currentAgeCategoryToDelete = ""
        this.setState({
            deleteModalShow:false
        })
    }

    memberTaxDeleteCancell(){
        this.currentAgeCategoryToDelete = ""
        this.setState({
            deleteModalShow:false
        })
    }

    newMemberTaxSubmit(){
        var valid = true;
        var errorMsg = ""
        var priceInt = parseInt(this.state.newMemberTaxPrice, 10)

        // validation
        if(priceInt < 0){
            valid = false;
            errorMsg = "Cena nesmí být záporná!"
        }

        if(valid){
            const data = {
                "age_category":this.state.newMemberTaxAgeCat,
                "price":this.state.newMemberTaxPrice
            }

            const memeberTaxDidCreate = (response, status) => {
                if(status === 201){
                    this.setState({
                        newMemberTaxValid:true,
                        newMemberTaxPrice: 0,
                        newMemberTaxError: "",
                        newMemberTaxAgeCat: this.state.memberTaxesAgeCategories[0][0]
                    })
    
                    this.loadMemberTaxes()
                }else if(status === 400){
                    if(response.age_category && response.age_category[0] === "member taxes with this Věková kategorie already exists.")
                    {
                        this.setState({
                            newMemberTaxValid: false,
                            newMemberTaxError: "Členský příspěvek pro tuto věkovou skupinu již existuje!"
                        })
                    }else{
                        this.setState({
                            newMemberTaxValid: false,
                            newMemberTaxError: "Vyskytl se problém, opakujte později!"
                        })
                    }
                }else{
                    this.setState({
                        newMemberTaxValid: false,
                        newMemberTaxError: "Vyskytl se problém, opakujte později!"
                    })
                }           
            }

            apiLicensesMemberTaxNew(memeberTaxDidCreate, data)

        }else{
            this.setState({
                newMemberTaxValid: valid,
                newMemberTaxError: errorMsg
            })
        }
    }

    render(){
        return(
            <div className="container licenses-container-wrapper mt-5">
                <div className="d-flex justify-content-center">
                    <h3>Ceny členských příspěvků pro rok {this.props.sellYear ? this.props.sellYear : ""}</h3>
                </div>
                <div className="container mt-3">
                    <div className="row">
                        {this.state.memberTaxes.map((taxItem, _index) => (
                            <div className="col py-3" key={`${taxItem.age_category}-${taxItem.price}`}> 
                                <div className="container d-flex member-tax-circle">
                                    <div className="row justify-content-center align-self-center">
                                        <div className="d-flex justify-content-center mt-2">
                                            <p className='tax-circle-txt'>{taxItem.format_age_category}</p>
                                        </div>
                                        <div className="d-flex justify-content-center mb-2">
                                            <h5>{taxItem.format_price}</h5>
                                        </div>
                                        {this.state.isUserAuth &&
                                        <div className="d-flex justify-content-center mb-2">
                                            <DeleteButton onBtnClick={() => this.memberTaxDeleteClicked(taxItem.age_category)}/>
                                        </div>
                                        }
                                    </div>                                   
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='d-flex justify-content-center mt-5'>
                        <i>Cena pro mládež 16-18 let platí i pro studenty do 26 let při předložení potvrzení o studiu.</i>
                    </div>
                    <ModalDialog 
                    show={this.state.deleteModalShow}
                    message="Odstranit cenu příspěvku?"
                    onBtn1={this.memberTaxDeleteOk}
                    onBtn2={this.memberTaxDeleteCancell}/>  
                </div> 
                {this.state.isUserAuth &&
                <div className="container">
                    <hr/>
                    <div className="d-flex justify-content-center">
                        <h5>Nová cena členského příspěvku</h5>
                    </div>
                    <form>                           
                        <div className="form-group mt-3">
                            <div className="d-flex justify-content-center">
                                <label className="sell-date-label">Věková skupina</label>                
                                <select className="form-select sell-date-input" value={this.state.newMemberTaxAgeCat} onChange={(e) => {this.setState({newMemberTaxAgeCat:e.target.value})}}>
                                    {this.state.memberTaxesAgeCategories.map((age_category_tuple, _index) => (
                                        <option value={age_category_tuple[0]} key={`${age_category_tuple[0]}`}>{age_category_tuple[1]}</option>
                                    ))}
                                </select>
                            </div>     
                        </div>
                        <div className="form-group mt-3">
                            <div className="d-flex justify-content-center">
                                <label className="sell-date-label">Cena</label>                
                                <div className="input-group sell-date-input">
                                    <input type="number" min="0" className="form-control" placeholder="Cena" value={this.state.newMemberTaxPrice} onChange={(e) => {this.setState({newMemberTaxPrice:e.target.value})}} required/>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">{this.props.currency ? this.props.currency : "Kč"}</span>
                                    </div>
                                </div>
                            </div>     
                        </div>     
                    </form>
                    <div className="container mt-3">
                        {!this.state.newMemberTaxValid &&
                            <div className="d-flex justify-content-center">
                                <p className="alert alert-danger sell-date-error" role="alert">
                                    {this.state.newMemberTaxError}
                                </p>
                            </div>
                        } 
                        <div className="d-flex justify-content-center mt-1">
                            <button className="btn btn-secondary" onClick={this.newMemberTaxSubmit}>Přidat cenu</button>
                        </div>
                    </div>
                </div>   
                }
            </div>
        )
    }

}