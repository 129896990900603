import React from 'react'
import {
    apiLicensesSellPricesLookup,
    apiLicenseSellPriceDelete,
    apiLicenseSellPriceNew,
} from '../actions'

import {
    apiLicensesAgeCategoriesLookup,
    apiLicensesTypesLookup,
} from '../../global/actions'

import {isUserAuth} from '../../auth/components'

import LicensesSellPricesCategoryComponent from './LicensesSellPricesCategoryComponent'

import trout from '../img/trout.png'
import carp from '../img/carp.png'

export default class LicensesSellPricesComponent extends React.Component{
    constructor(props){
        super(props)

        // constants defined in backed
        this.nonTroutLicenseType = "NTRT"
        this.troutLicenseType = "TRT"

        this.state = ({
            // pstruhove celosvaz
            trtUniSellPrices: [],

            // pstruhove kraj
            trtLocalSellPrices: [],

            // mimopstruhove celosvaz
            nonTrtUniSellPrices: [],

            // mimopstruhove kraj
            nonTrtLocalSellPrices: [],

            // kategorie povolenek
            licensesTypes: [],

            // vekove skupiny pro povolenky
            licensesAgeCategories: [],

            // nova cena povolenky state
            newLicensePriceType: "",
            newLicensePriceAgeCat: "",
            newLicensePricePrice: 0,
            newLicensePriceUniversal: false,
            newLicensePriceValid: true,
            newLicensePriceError: "",
            isUserAuth:false,
        })

        this.loadSellPrices             = this.loadSellPrices.bind(this)
        this.deleteLicenseSellPrice     = this.deleteLicenseSellPrice.bind(this)
        this.loadLicensesTypes          = this.loadLicensesTypes.bind(this)
        this.loadLicensesAgeCategories  = this.loadLicensesAgeCategories.bind(this)
        this.newLicensePriceSubmit      = this.newLicensePriceSubmit.bind(this)
        this.loadUserAuth               = this.loadUserAuth.bind(this)
    }

    componentDidMount(){
        this.loadUserAuth()
        this.loadSellPrices()
    }

    loadUserAuth(){
        const userAuthDidLoad = (response, status) => {
            if(status === 200){
                if(response.is_auth && response.is_auth === "yes"){
                    this.setState({
                        isUserAuth: true,
                    })
                    this.loadLicensesTypes()
                    this.loadLicensesAgeCategories()
                }
            }else{
                this.setState({
                    isUserAuth: false,
                })
            }      
        }
        isUserAuth(userAuthDidLoad) 
    }

    loadSellPrices(){
        const sellPricesDidLoaded = (response) => {
            if(response !== null){
                // process the prices, divide them to two lists, one for Trout type, second for non trout type
                var newTrtUniSellPrices = []
                var newTrtLocalSellPrices = []
                var newNonTrtUniSellPrices = []
                var newNonTrtLocalSellPrices = []

                const licensesSellPrices = response
                for(var i = 0; i < licensesSellPrices.length; i++){
                    if(licensesSellPrices[i].license_type === this.nonTroutLicenseType){
                        if(licensesSellPrices[i].universal === true){
                            newNonTrtUniSellPrices.push(licensesSellPrices[i])
                        }else{
                            newNonTrtLocalSellPrices.push(licensesSellPrices[i])
                        }
                    }else if(licensesSellPrices[i].license_type === this.troutLicenseType){
                        if(licensesSellPrices[i].universal === true){
                            newTrtUniSellPrices.push(licensesSellPrices[i])
                        }else{
                            newTrtLocalSellPrices.push(licensesSellPrices[i])
                        }                      
                    }
                }

                this.setState({
                    trtUniSellPrices:newTrtUniSellPrices,
                    trtLocalSellPrices:newTrtLocalSellPrices,
                    nonTrtUniSellPrices:newNonTrtUniSellPrices,
                    nonTrtLocalSellPrices:newNonTrtLocalSellPrices
                })
            }
        }
        apiLicensesSellPricesLookup(sellPricesDidLoaded)
    }

    loadLicensesTypes(){
        const licensesTypesDidLoad = (response, status) => {
            if(status === 200){
                this.setState({
                    licensesTypes: response.licenses_types,
                    newLicensePriceType: response.licenses_types[0][0],
                })
            }
        }
      
        if(this.state.isUserAuth)
        {
            apiLicensesTypesLookup(licensesTypesDidLoad)
        }
    }

    loadLicensesAgeCategories(){
        const licensesAgeCategoriesDidLoad = (response, status) => {
            if(status === 200){
                this.setState({
                    licensesAgeCategories: response.licenses_age_categories,
                    newLicensePriceAgeCat: response.licenses_age_categories[0][0],
                })
            }
        }

        //apiLicensesAgeCategoriesLookup(licensesAgeCategoriesDidLoad)
        
        if(this.state.isUserAuth)
        {
            apiLicensesAgeCategoriesLookup(licensesAgeCategoriesDidLoad)
        }
    }

    deleteLicenseSellPrice(id){
        const licenseSellPriceDidDelete = (_response, status) => {
            if(status === 204){
                this.loadSellPrices()
            }
        }
        apiLicenseSellPriceDelete(licenseSellPriceDidDelete, id)
    }

    newLicensePriceSubmit(){
        var valid = true
        var errorMsg = ""
        var priceInt = parseInt(this.state.newLicensePricePrice, 10)

        if(priceInt < 0){
            valid = false
            errorMsg = "Cena nesmí být záporná"
        }

        if(valid){
            const data = {
                'license_type': this.state.newLicensePriceType,
                'age_category': this.state.newLicensePriceAgeCat,
                'universal':    this.state.newLicensePriceUniversal,
                'price':        this.state.newLicensePricePrice
            }

            const newLicenseSellPriceDidCreate = (response, status) => {

                if(status === 201){
                    this.setState({
                        newLicensePriceType: this.state.licensesTypes[0][0],
                        newLicensePriceAgeCat: this.state.licensesAgeCategories[0][0],
                        newLicensePriceError: "",
                        newLicensePricePrice: 0,
                        newLicensePriceUniversal: false,
                        newLicensePriceValid: true
                    })
                    this.loadSellPrices()
                }
                else if(status === 400){
                    if(response.non_field_errors && response.non_field_errors[0] === "The fields license_type, age_category, universal must make a unique set.")
                    {
                        this.setState({
                            newLicensePriceError: "Cena pro danou kombinaci již existuje!",
                            newLicensePriceValid: false
                        })
                    }else{
                        this.setState({
                            newLicensePriceError: "Vyskytl se problém, opakujte později",
                            newLicensePriceValid: false
                        })
                    }
                }else{
                    this.setState({
                        newLicensePriceError: "Vyskytl se problém, opakujte později",
                        newLicensePriceValid: false
                    })
                }
            }

            apiLicenseSellPriceNew(newLicenseSellPriceDidCreate, data)

        }else{
            this.setState({
                newLicensePriceValid:valid,
                newLicensePriceError:errorMsg
            })
        }
    }

    render(){
        return(
            <div className="container licenses-container-wrapper mt-5">
                <div className="d-flex justify-content-center">
                    <h3>Ceny povolenek pro rok {this.props.sellYear ? this.props.sellYear : ""}</h3>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="container">
                            <div className="row d-flex justify-content-center">
                                <img className="trout-carp-img" src={trout} alt="Pstruhové povolenky" title="Pstruhové povolenky"/>     
                                <h4 className="text-center mt-2">Krajské pstruhové povolenky</h4> 
                                <LicensesSellPricesCategoryComponent
                                    elements={this.state.trtLocalSellPrices}
                                    onDelete={this.deleteLicenseSellPrice}
                                    isUserAuth={this.state.isUserAuth}/>
                                 <h4 className="text-center mt-5">Celosvazové pstruhové povolenky</h4> 
                                 <LicensesSellPricesCategoryComponent
                                    elements={this.state.trtUniSellPrices}
                                    onDelete={this.deleteLicenseSellPrice}
                                    isUserAuth={this.state.isUserAuth}/>
                            </div> 

                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="container">
                            <div className="row d-flex justify-content-center">
                                <img className="trout-carp-img" src={carp} alt="Mimopstruhové povolenky" title="Mimopstruhové povolenky"/>
                                <h4 className="text-center mt-2">Krajské mimopstruhové povolenky</h4> 
                                <LicensesSellPricesCategoryComponent
                                    elements={this.state.nonTrtLocalSellPrices}
                                    onDelete={this.deleteLicenseSellPrice}
                                    isUserAuth={this.state.isUserAuth}/>
                                 <h4 className="text-center mt-5">Celosvazové mimopstruhové povolenky</h4>
                                 <LicensesSellPricesCategoryComponent
                                     elements={this.state.nonTrtUniSellPrices}
                                     onDelete={this.deleteLicenseSellPrice}
                                     isUserAuth={this.state.isUserAuth}/>
                            </div>                                                       
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center mt-5'>
                    <i>Cena pro mládež 16-18 let platí i pro studenty do 26 let při předložení potvrzení o studiu.<br/></i>
                </div>
                <div className='d-flex justify-content-center'>
                    <i>Pro uplatnění slevy na invalidu je třeba předložit průkaz ZTP.</i>
                </div>
                <div className='d-flex justify-content-center'>
                    <b>Místní organizace Klášterec nad Orlicí neprodává hostovké ani nečlenské povolenky.</b>
                </div>
                {this.state.isUserAuth &&
                <div className="container mt-5">
                    <hr/>
                    <div className="d-flex justify-content-center">
                        <h5>Nová cena povolenky</h5>
                    </div>
                    <form>               
                        <div className="form-group mt-3">
                            <div className="d-flex justify-content-center">
                                <label className="sell-date-label">Typ povolenky</label>                
                                <select className="form-select sell-date-input" value={this.state.newLicensePriceType} onChange={(e) => this.setState({newLicensePriceType:e.target.value})}>
                                    {this.state.licensesTypes && this.state.licensesTypes.map((licenseTypeTuple, _index) => (
                                        <option value={licenseTypeTuple[0]} key={`${licenseTypeTuple[0]}`}>{licenseTypeTuple[1]}</option>
                                    ))}
                                </select>
                            </div>     
                        </div>            
                        <div className="form-group mt-3">
                            <div className="d-flex justify-content-center">
                                <label className="sell-date-label">Věková skupina</label>                
                                <select className="form-select sell-date-input" value={this.state.newLicensePriceAgeCat} onChange={(e) => this.setState({newLicensePriceAgeCat:e.target.value})}>
                                    {this.state.licensesAgeCategories && this.state.licensesAgeCategories.map((licenseAgeCategoryTuple, _index) => (
                                        <option value={licenseAgeCategoryTuple[0]} key={`${licenseAgeCategoryTuple[0]}`}>{licenseAgeCategoryTuple[1]}</option>
                                    ))}
                                </select>
                            </div>     
                        </div>
                        <div className="form-group mt-3">
                            <div className="d-flex justify-content-center">
                                <label className="sell-date-label">Cena</label>                
                                <div className="input-group sell-date-input">
                                    <input type="number" min="0" className="form-control" placeholder="Cena" value={this.state.newLicensePricePrice} onChange={(e) => this.setState({newLicensePricePrice:e.target.value})} required/>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">{this.props.currency ? this.props.currency : "Kč"}</span>
                                    </div>
                                </div>
                            </div>     
                        </div>    
                        <div className="form-group mt-3">
                            <div className="d-flex justify-content-center">        
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" checked={this.state.newLicensePriceUniversal} onChange={(e) => this.setState({newLicensePriceUniversal:e.target.checked})}/>
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        Celosvazová
                                    </label>
                                </div>
                            </div>     
                        </div> 
                    </form>
                    <div className="container mt-3">
                        {!this.state.newLicensePriceValid &&
                            <div className="d-flex justify-content-center">
                                <p className="alert alert-danger sell-date-error" role="alert">
                                    {this.state.newLicensePriceError}
                                </p>
                            </div>
                        } 
                        <div className="d-flex justify-content-center mt-1">
                            <button className="btn btn-secondary" onClick={this.newLicensePriceSubmit}>Přidat cenu</button>
                        </div>
                    </div>
                </div>}
            </div>
        )
    }
}