import {backendRequest} from '../backend/'

export function apiLicensesSellYearLookup(callback){
    return backendRequest("GET", '/licenses-sell/year/', callback, {})
}

export function apiLicensesSellYearCreate(callback, data){
    return backendRequest("POST", '/licenses-sell/year/', callback, data)
}

export function apiLicensesSellDatesLookup(callback){
    return backendRequest("GET", '/licenses-sell/dates/', callback, {})
}

export function apiLicensesSellPricesLookup(callback){
    return backendRequest("GET", '/licenses-sell/prices/', callback, {})
}

export function apiLicensesSellMemberFloodTaxesLookup(callback){
    return backendRequest("GET", '/licenses-sell/flood-taxes/', callback, {})
}

export function apiLicensesSellMemberTaxesLookup(callback){
    return backendRequest("GET", '/licenses-sell/taxes/', callback, {})
}

export function apiLicensesSellYearPatch(callback, year_id, data){
    return backendRequest("PATCH", `/licenses-sell/year/${year_id}/`, callback, data)
}

export function apiLicenseSellDateDelete(callback, id){
    return backendRequest("DELETE", `/licenses-sell/dates/${id}/`, callback, {})
}

export function apiLicenseSellDateNew(callback, data){
    return backendRequest("POST", '/licenses-sell/dates/', callback, data)
}

export function apiLicensesMemberTaxDelete(callback, age_category){
    return backendRequest("DELETE", `/licenses-sell/taxes/${age_category}/`, callback, {})
}

export function apiLicensesMemberFloodTaxDelete(callback, age_category){
    return backendRequest("DELETE", `/licenses-sell/flood-taxes/${age_category}/`, callback, {})
}

export function apiLicensesMemberTaxNew(callback, data){
    return backendRequest("POST", '/licenses-sell/taxes/', callback, data)
}

export function apiLicensesMemberFloodTaxNew(callback, data){
    return backendRequest("POST", '/licenses-sell/flood-taxes/', callback, data)
}

export function apiLicenseSellPriceDelete(callback, id){
    return backendRequest("DELETE", `/licenses-sell/prices/${id}/`, callback, {})
}

export function apiLicenseSellPriceNew(callback, data){
    return backendRequest("POST", '/licenses-sell/prices/', callback, data)
}